import React, { Component, useState, useCallback, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress, {
    circularProgressClasses,
  } from '@mui/material/CircularProgress';


import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, registerInitHook } from "../network";
import FileDownloader from "../file-download";
import { ProcessOptionsDialog } from "./management-workflow-dialogs";
import { ProcessLogViewer } from "./process";
import { openPdfDocument, openDCRBinder }  from "../webviewer";
import { SelectPdfDraftDialog, UploadFileDialog, SimpleUploadFileDialog, ViewDraftOutputDialog, AbortConfirmDialog } from "./workflow-select-draft";
import { EvaluateFormDialog } from "./management-workflow-forms";
import { WorkflowActionButtons, CanActionClaim } from "./workflow-step-actions";
// import { PortfolioTest } from "./workflow-portfolio";

var wreload = 0;
const DEBOUNCE_TIME = 120;
const DEFAULT_APPROVAL_DRAFT_LABEL = "Review Approval Draft";
const CANDIDATE_DRAFT_LABEL = "Edit Candidate Draft";
const REVIEW_CANDIDATE_DRAFT_LABEL = 'Review Candidate Draft';
const ANNOTATE_CANDIDATE_DRAFT_LABEL = 'Annotate Candidate Draft';
const OEM_REVISION_COPY_LABEL = 'Annotate OEM DCR';
const REVIEW_OEM_DCR_LABEL = 'Review OEM DCR';
const ANNOTATE_OEM_DCR_LABEL = 'Annotate OEM DCR';

function ChooseReviewDCRBindersDialog(props) {
    const {
        open,
        documents,
        onCancel,
        onSave,
        refPortfolio,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (binder) => {
        /*
        WorkflowModule.pageIndexChange(1);
        const { annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + file);
        */
        if (Array.isArray(binder.files)) {
            openDCRBinder(binder.files, refPortfolio);
            if ( typeof onSave === 'function' ) {
                onSave(binder.binderId);
            }
        }
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose the DCR to Review</DialogTitle>
            <DialogContent>
                {Array.isArray(documents) &&
                    documents.map(doc => 
                        <Box>
                            <Box><Typography sx={{fontWeight: 'bold'}}>{doc.title}</Typography></Box>
                            <List>
                                {Array.isArray(doc.binders) && doc.binders.map(item => (
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => handleFileClick(item)}>
                                            <ListItemIcon>
                                                <OpenInNewIcon fontSize="0.92em" fontWeight="bold" />
                                            </ListItemIcon>
                                            <ListItemText primary={`${item.name}: ${item.description}`} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                                )
                                }
                            </List>
                        </Box>)
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function ChooseViewPdfDialog(props) {
    const {
        open,
        files,
        onCancel,
        previousDrafts,
        dcrPortfolio,
        oemPortfolio,
        refPortfolio,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (file, label, base) => {
        /*
        WorkflowModule.pageIndexChange(1);
        const { annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + file);
        */
        const isCand = typeof base !== 'undefined' ? base : false;
        openPdfDocument(file, label, previousDrafts, dcrPortfolio, oemPortfolio, isCand, refPortfolio);
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose the PDF File to Open</DialogTitle>
            <DialogContent>
                <List>
                    { files.map( item => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleFileClick(item.path, item.label, item.base)}>
                                <ListItemIcon>
                                    <OpenInNewIcon fontSize="0.92em" fontWeight="bold"/>
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                    )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function DownloadFilesDialog(props) {
    const {
        title = 'Download Files',
        open,
        files,
        onCancel,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    useEffect( () => {
        if ( open && files ) {
            console.log('DOWN DIALOG: ' + JSON.stringify(files));
        }

    },[open,files]);


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 900,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <FileDownloader files={files} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Close</Button>
            </DialogActions>
        </Dialog>

    );

}

function WorkflowStep(props) {
    const {
        workflowInstance,
        workflowDef,
        workflowDefList,
        workflowList,
        stepInstance,
        stepDef,
        dcrPortfolio,
        oemPortfolio,
        refPortfolio,
        ...other 
    } = props;

    const [activeStep, setActiveStep] = React.useState(-1);
    const [selectPdfOpen, setSelectPdfOpen] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);
    const [evaluateFormOpen, setEvaluateFormOpen] = React.useState(false);
    const [formInstance, setFormInstance] = React.useState(null);
    const [destStepIndex, setDestStepIndex] = React.useState(0);
    const [uploadDcrOpen, setUploadDcrOpen] = React.useState(false);
    const [uploadDlibOpen, setUploadDlibOpen] = React.useState(false);
    const [uploadActionIndex, setUploadActionIndex] = React.useState(-1);
    const [draftOptionsOpen, setDraftOptionsOpen] = React.useState(false);
    const [draftOptions, setDraftOptions] = React.useState({});
    const [editionOptionsFlag, setEditionOptionsFlag] = React.useState(false);
    const [viewDraftOutputOpen, setViewDraftOutputOpen] = React.useState(false);
    const [viewDraftEnable, setViewDraftEnable] = React.useState(true);
    const [approvalDraftEnable, setApprovalDraftEnable] = React.useState(true);
    const [approvalDraftLabel, setApprovalDraftLabel] = React.useState(DEFAULT_APPROVAL_DRAFT_LABEL);
    const [candidateDraftEnable, setCandidateDraftEnable] = React.useState(true);
    const [candidateDraftLabel, setCandidateDraftLabel] = React.useState(CANDIDATE_DRAFT_LABEL);
    const [oemCopyEnable, setOemCopyEnable] = React.useState(true);
    const [oemCopyLabel, setOemCopyLabel] = React.useState(OEM_REVISION_COPY_LABEL);
    const [viewPdfs, setViewPdfs] = React.useState([]);
    const [viewPdfOpen, setViewPdfOpen] = React.useState(false);
    const [viewEditionOutputOpen, setViewEditionOutputOpen] = React.useState(false);
    const [viewProcessLogsOpen, setViewProcessLogsOpen] = React.useState(false);
    const [abortDialogOpen, setAbortDialogOpen] = React.useState(false);
    const [previousDrafts, setPreviousDrafts] = React.useState([]);
    const [actionIndex, setActionIndex] = React.useState(-1);
    const [downloadFiles, setDownloadFiles] = React.useState([]);
    const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
    const [uploadDlibShelf, setUploadDlibShelf] = React.useState(null);
    const [uploadDlibDescription, setUploadDlibDescription] = React.useState('');
    const [dcrDocuments, setDcrDocuments] = React.useState([]);
    const [dcrDocumentReviewOpen, setDcrDocumentReviewOpen] = React.useState(false);
    const [dcrBinderId, setDcrBinderId] = React.useState('');
    const [actionCanClaim, setActionCanClaim] = React.useState(true);

    const [processInstId, setProcessInstId] = React.useState('');
    // steps' SIDs at this level, excluding the nested subworkflows steps
    const [levelSteps, setLevelSteps] = React.useState([]);
    // all def steps including substeps
    const [allSteps, setAllSteps] = React.useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    React.useEffect(() => {
        if ( Array.isArray(workflowDef?.steps) ) {
            let lsteps = [];
            workflowDef.steps.forEach( item => {
                if ( ! item.sid ) {
                    item.sid = item.id;
                }
                if ( ! item.properties ) {
                    item.properties = {};
                }
                if ( ! item.properties.hnum ) {
                    item.properties.hnum = `${item.id + 1}`;
                }
                lsteps.push(item.sid);
            });
            setLevelSteps(lsteps);

            let asteps = [];
            loadAllSteps(asteps, workflowDef.steps);
            setAllSteps(asteps);
        }

    }, [workflowDef]);

    React.useEffect(() => {
        setActiveStep(workflowInstance.stepId);
        console.log('workflowInstance changed...')

    }, [workflowInstance]);

    React.useEffect(() => {
        // setDestStepIndex(getGotoStepIndex(stepInstance));
        setDestStepIndex('');
        // console.log('Type of startDate: ' + typeOf(si.startDate) + ' = ' + si.startDate);
        if (Array.isArray(stepInstance?.properties?.actions)) {
            let needsInfo = false;
            stepInstance.properties.actions.forEach(item => {
                if (item.name === 'RUN_DRAFT' || item.name === 'RUN_APPROVAL_DRAFT' || item.name === 'RUN_EDITION_PAUSE') {
                    if (item.properties) {
                        const options = { ...item.properties };
                        setDraftOptions(options);
                    }
                } else if ( item.name === 'VIEW_DRAFT' || item.name === 'VIEW_APPROVAL_DRAFT' ) {
                    needsInfo = true;
                    setApprovalDraftEnable(true);
                    setApprovalDraftLabel(DEFAULT_APPROVAL_DRAFT_LABEL);
                    setViewDraftEnable(true);
                    netPost('/api/workflow/step/inst/info', stepInstance)
                        .then(response => response.json())
                        .then(info => {
                            if ( !(Array.isArray(info.files) && info.files.length > 0 ) ) {
                                setViewDraftEnable(false);
                            }
                            if ( !(Array.isArray(info.file) && info.file.length > 0 ) ) {
                                setApprovalDraftEnable(false);
                            } else {
                                const ifile = info.file[0];
                                if ( typeof ifile?.fid === 'string' && ifile.fid.length > 0 ) {
                                    setApprovalDraftLabel(CANDIDATE_DRAFT_LABEL);
                                }
                            }
                        });
                } else if ( item.name === 'REVIEW_CAND_DRAFT' || item.name === 'APPROVE_CAND_DRAFT' ) {
                    netPost('/api/workflow/step/inst/info', stepInstance)
                        .then(response => response.json())
                        .then(info => {
                            if ( Array.isArray(info.files) ) {
                                console.log('Got candidate draft: ' + info.files.length);
                            }
                        });
                }
            });
            setActionCanClaim(false); // lock it until we can resolve the state
            const claimList = stepInstance.properties.actions.map( action => CanActionClaim({ stepInstance: stepInstance, action: action}));
            Promise.all(claimList).then ( claims => {
                // console.log('ALL CLAIMS = ' + JSON.stringify(claims));
                // result is AND of all claims
                const res = claims.reduce(
                    (total, current) => total && current,
                    true
                );
                // console.log('Resolving ALL Claims: ' + res);
                setActionCanClaim(res);
            });
        } else {
            setActionCanClaim(true);
        }
        console.log('new stepInstance: ' + JSON.stringify(stepInstance?.properties?.name));
    },[stepInstance]);

    const loadAllSteps = (stepList, steps) => {
        if (Array.isArray(steps) && Array.isArray(stepList)) {
            steps.forEach(item => {
                stepList.push(item);
                if (item.name === 'CALL') {
                    if (item.properties?.substeps) {
                        loadAllSteps(stepList, item.properties.substeps);
                    }
                }
            });
        }
    };

    const getStepRoutes = (stepDef, workflowDef, workflowList, stepInst=stepInstance) => {
        if ( Array.isArray(stepDef.routes) && stepDef.routes.length > 0 ) {
            // console.log('Step Instance: ' + JSON.stringify(stepInst));
            // console.log('Step Instance routes: ' + JSON.stringify(stepInst?.properties?.routes));
            const routes = [...stepDef.routes];
            /*
            const disRoutes = stepInst.properties?.disableRoutes;
            stepDef.routes.forEach( r => {
                if ( Array.isArray(disRoutes) ) {
                    if ( ! disRoutes.includes(r.targetSid) ) {
                        routes.push(r);
                    }
                } else {
                    routes.push(r);
                }
            });
            */
            if ( stepInst ) {
                const rr = stepInst.properties?.routes;
                if ( Array.isArray(rr) && rr.length > 0 ) {
                    rr.forEach( rri => routes.push(rri));
                }
            }
            if ( workflowDef && workflowList ) {
                routes.forEach( item => resolveStepDestinationLabel(item, workflowDef, workflowList));
            }
            return routes;
        }
        // default is NEXT
        return [{ id: 0, name: 'NEXT', label: 'NEXT STEP'}];
    };

    const isRouteDisabled = (route) => {
        if ( Array.isArray(stepInstance.properties?.disableRoutes) && stepInstance.properties.disableRoutes.length > 0 ) {
            if ( route.targetSid ) {
                return stepInstance.properties.disableRoutes.includes(route.targetSid);
            } else if ( route.name === 'NEXT' && stepDef?.sid ) {
                const nid = stepDef.sid + 1;
                return stepInstance.properties.disableRoutes.includes(nid);
            }
            
        }
        return false;
    };

    const resolveStepDestinationLabel = (route, wdef, wlist) => {
        switch (route.name) {
            case ('STEP'):
                // console.log('Resolving label for route: ' + JSON.stringify(route));
                if ( route.targetSid ) {
                    const tstep = findTargetStep(wdef.steps, route.targetSid);
                    if ( tstep && tstep.properties) {
                        const p = tstep.properties;
                        route.label = `STEP ${p.hnum}: ${tstep.label}`;
                    }
                } else {
                    route.label = `STEP ${route.targetId+1}${route.targetId >=0 ? ': ' + wdef.steps[route.targetId].label : ''}`;
                }
                break;
            case ('WORKFLOW'):
                let dest = 'WORKFLOW:  ';
                if ( route.targetWorkflowId >= 0 ) {
                    const w = wlist.find( item => item.id === route.targetWorkflowId );
                    if ( w ) {
                        dest = dest + w.label ;
                    }
                    if ( route.targetId >= 0 && Array.isArray(w.steps)) {
                        dest = dest + ' / ' + w.steps[route.targetId].label;
                    }
                } 
                route.label = dest;
                break;
            default:
                route.label = 'NEXT STEP';
                break;
                
        }
    };

    const findTargetStep = (steps, sid) => {
        let target = undefined;
        for(const item of steps) {
            if ( sid === item.sid) {
                target = item;
                break;
            }
            if ( item.name === 'CALL') {
                if ( item.properties ) {
                    const substeps = item.properties.substeps;
                    if ( substeps ) {
                        target = findTargetStep(substeps, sid);
                        if ( target ) {
                            break;
                        }
                    }
                }
            }
        }
        return target;
    }

    const getGotoStepIndex = (stepInst) => {
        if ( stepInst && stepInst.properties && stepInst.properties.gotoStepIndex ) {
            return stepInst.properties.gotoStepIndex;
        }
        return 0;
    };

    const handleGotoStepChange = (event, stepInst) => {
        const gotoIndex = event.target.value;
        stepInst.properties.gotoStepIndex = gotoIndex;
        setDestStepIndex(gotoIndex);
        netPost('/api/workflow/step/update' , stepInst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                    
                }
            });

    };

    // has a lock and can perform actions in the step, not necessarily go to next or routed step
    const canAction = () => {
        const bl = getButtonLabel();
        const r = (bl === 'Complete' || bl === 'Go');
        // console.log('can complete: ' + bl + ' : ' + r);
        return r;
    };

    // can perform the "complete" function e.g. go to next or routed step.
    const canComplete = () => {
        const bl = getButtonLabel();
        const r = (bl === 'Complete' || bl === 'Go') && !getButtonDisabled();
        // console.log('can complete: ' + bl + ' : ' + r);
        return r;
    };

    const getButtonRolesDisabled = () => {
        if ( ! stepInstance ) {
            return true;
        }
        // console.log('Current step: ' + instance.stepId);
        const stepDef = allSteps[workflowInstance.stepId];
        // console.log('Current workflow def: ' + JSON.stringify(workflowDef));
        // console.log('Current step def:' + workflowDef.id + ' : ' +  JSON.stringify(stepDef));
        // console.log('Current step inst: ' + JSON.stringify(stepInstance));
        // console.log('Current user: ' + JSON.stringify(UserInfo.info));
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if ( stepInstance.properties.stepUsers.includes(UserInfo.info.name) ) {
                return false;
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return !(stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) || 
                        (Array.isArray(stepInstance.properties.participants) && stepInstance.properties.participants.includes(UserInfo.info.name)));
                } else {
                    return false;
                }
            } else if ( stepInstance.properties.stepUsers.length > 0 ) {
                return true;
            } else if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                return !(stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) ||
                    (Array.isArray(stepInstance.properties.participants) && stepInstance.properties.participants.includes(UserInfo.info.name)));
            } else {
                return false;
            }
        } else {
            // console.log("Active step: I don't have users");
            if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                const ican = !(stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) ||
                    (Array.isArray(stepInstance.properties.participants) && stepInstance.properties.participants.includes(UserInfo.info.name)));
                // console.log('I can claim: ' + ican);
                return ican;
            } else {
                return false;
            }
        }
        return true;
        // return stepInstance && UserInfo.info.name === stepInstance.user ? false : !(stepInstance && !stepInstance.user);
    };

    const getButtonDisabled = () => {
        
        let rd = getButtonRolesDisabled();
        

        // console.log('STEP TYPE: ' + stepInstance?.properties?.name);
        const buttonLabel = getButtonLabel();
        /*
        if ( stepInstance?.properties?.state === 'RUNNING' ) {
            return true;
        }
        */
        if ( stepInstance?.properties?.name === 'FORM' && buttonLabel === 'Go' ) {
            if ( Array.isArray(stepInstance.properties?.form?.fields) ) {
                const fields = stepInstance.properties.form.fields;
                for(let i=0; i<fields.length; i++) {
                    const field = fields[i];
                    if ( field.required ) {
                        if ( typeof field.value === 'undefined' ) {
                            return true;
                        }
                        if ( field.value === '' ) {
                            return true;
                        }
                    }
                }
            }
        }
        if ( stepInstance?.properties?.actions && buttonLabel === 'Go' ) {
            stepInstance.properties.actions.forEach( action => {
                if ( action.name === 'UPLOAD' && action.target === 'DCR' ) {
                    if ( typeof action.path !== 'string' ) {
                        rd = true;
                    }
                } else if ( action.name === 'UPLOAD' && action.target === 'DLUP') {
                    console.log('DLUP files = ' + JSON.stringify(action.properties?.files));
                    if ( ! (Array.isArray(action.properties?.files) && action.properties.files.length > 0) ) {
                        rd = true;
                        console.log('DLUP: disabled: ' + true);
                    } else {
                        console.log('DLUP disabled = ' + false);
                        
                    }
                }
            });
        }

        if ( Array.isArray(stepInstance?.properties?.actions) && stepInstance.properties.actions.length > 0 && buttonLabel === 'Claim' ) {
            rd = rd || !actionCanClaim;
            // console.log('Claim disabled: ' + actionCanClaim + ' rd=' + rd);
        }

        return rd;
    };

    const getButtonRoutesEnabled = () => {
        let ri = getStepRoutes(stepDef).length > 1 ? destStepIndex !== '' && destStepIndex >= 0 : true;
        console.log('Button Routes enabled = ' + ri);
        return ri;
    };


    const getButtonLabel = () => {
        if ( ! stepInstance ) {
            return 'Claim';
        }
        const stepDef = allSteps[workflowInstance.stepId];
        // console.log('Current step:' + stepDef.name);
        // console.log('Instance: ' + JSON.stringify(stepInstance));
        /*
        if ( stepInstance?.properties?.state === 'RUNNING' ) {
            return 'Go';
        }
        */
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if (stepInstance.properties.stepUsers.includes(UserInfo.info.name)) {
                let verb = 'Go';
                /*
                if (Array.isArray(stepInstance?.properties?.actions)) {
                    stepInstance.properties.actions.forEach(action => {
                        if (action.name === 'RUN_DRAFT' || action.name === 'RUN_APPROVAL_DRAFT' || action.name === 'RUN_EDITION_PAUSE') {
                            verb = 'Process';
                        }
                    });
                }
                */
                return verb;
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) ? 'Claim' : 'Claim'; // Claim label anyway since it will be disabled if not relevant
                }
            }
        }
        return 'Claim';
    };

    const handleOpenDoc = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening link ' + info.link);
                // window.open('https://test-2014.uniscope.cloud/ucs/#content/editor', 'UCS');
                /*
                if ( window.opener ) {
                    window.opener.location.href = info.link;
                    window.opener.focus();
                } else {
                    window.open(info.link, 'UCS');
                }
                */
                window.open(info.link, 'UCS');
            });
    };

    const handleCheckPdfDraft = (sinst) => {
        const pfile = workflowInstance.properties?.pdfDraftFile;
        console.log('Opening PDF file ' + pfile);
        if (Array.isArray(pfile)) {
            console.log('Opening PDF file array: ' + pfile.length);
            if (pfile.length == 1) {

                if (workflowInstance.properties.previousDrafts && workflowInstance.properties.previousDrafts.length > 0) {
                    const prevDraft = workflowInstance.properties.previousDrafts[workflowInstance.properties.previousDrafts.length - 1];
                    if (Array.isArray(prevDraft) && prevDraft.length > 0) {
                        const ppath = prevDraft[0].path;
                        console.log('Found previous draft = ' + ppath);
                        const fpath = pfile[0].path;
                        if (fpath) {
                            const flabel = pfile[0].label;
                            openPdfDocument(fpath, flabel, workflowInstance.properties.previousDrafts, dcrPortfolio,  undefined, false, refPortfolio);
                        }
                    }
                } else {

                    const fpath = pfile[0].path;
                    const flabel = pfile[0].label;
                    if (fpath) {
                        openPdfDocument(fpath, flabel, undefined, dcrPortfolio, undefined, false, refPortfolio);
                    }
                }
            } else if (pfile.length > 1) {
                setViewPdfs(pfile);
                setPreviousDrafts(workflowInstance.properties.previousDrafts);
                setViewPdfOpen(true);
            }
        } else {
            openPdfDocument(pfile, undefined, undefined, dcrPortfolio,  undefined, false, refPortfolio);
        }
           
    };

    const handleCandidateDraft = (sinst) => {
        // TODO: show only OEM and DCR files
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                const pfile = workflowInstance.properties?.pdfDraftFile;
                console.log('Opening PDF file ' + pfile);
                if (Array.isArray(pfile)) {
                    console.log('Opening PDF file array: ' + pfile.length);
                    if (pfile.length == 1) {
                        const fpath = pfile[0].path;
                        const flabel = pfile[0].label;
                        const isCand = typeof pfile[0].base !== 'undefined' ? pfile[0].base : true;
                        if (fpath) {
                            openPdfDocument(fpath, flabel, undefined, dcrPortfolio, undefined, isCand, refPortfolio);
                        }
                    } else if (pfile.length > 1) {
                        setViewPdfs(pfile);
                        setPreviousDrafts([]);
                        setViewPdfOpen(true);
                    }
                } else {
                    openPdfDocument(pfile, undefined, undefined, dcrPortfolio, undefined, true, refPortfolio);
                }
            });
           
    };

    const handleEditOemCopy = (sinst) => {
        // TODO: show only OEM and DCR files
        const pfile = workflowInstance.properties?.oemCopyFile;
        console.log('Opening PDF file ' + pfile);
        if (Array.isArray(pfile)) {
            console.log('Opening PDF file array: ' + pfile.length);
            if (pfile.length == 1) {
                const fpath = pfile[0].path;
                const flabel = pfile[0].label;
                const isOem = typeof pfile[0].oem !== 'undefined' ? pfile[0].base : true;
                if (fpath) {
                    openPdfDocument(fpath, flabel, undefined, dcrPortfolio, undefined, false, refPortfolio);
                }
            } else if (pfile.length > 1) {
                setViewPdfs(pfile);
                setPreviousDrafts([]);
                setViewPdfOpen(true);
            }
        } else {
            openPdfDocument(pfile, undefined, undefined, dcrPortfolio, undefined, false, refPortfolio);
        }
           
    };

    const handleCheckOemCopy = (sinst) => {
        handleEditOemCopy(sinst);
    };

    const handleReviewDCR = (sinst, action) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening Review DCR files ' + JSON.stringify(info.file));
                if ( Array.isArray(info.file) && info.file.length >= 1 ) {
                    openDCRBinder(info.file, refPortfolio);   
                } else if ( Array.isArray(info.documents) && info.documents.length > 0 ) {
                    setDcrDocuments(info.documents);
                    setDcrDocumentReviewOpen(true);
                }
            }).catch( error => console.log('Error getting DCR info: ' + error));        
           
    };

    const handleReviewDCRSave = (binderId) => {
        setDcrBinderId(binderId)
        const winst = {...workflowInstance};
        winst.properties.approveBinderId = binderId;
        netPost('/api/workflow/inst/update', winst)
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);

                }
            })
            .catch(error => console.log('Error updating DCR binderId: ' + error));
    };

    const handleApproveDCR = (sinst, index, action) => {
        handleStartAction(sinst, index, action);
    };

    const handleRejectDCR = (sinst, index, action) => {
        handleStartAction(sinst, index, action, 'REJECT');
    };


    const handleCheckPdfDraftOld = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening PDF file ' + info.file);
                if ( Array.isArray(info.file) ) {
                    console.log('Opening PDF file array: ' + info.file.length);
                    if ( info.file.length == 1 ) {
                        
                        if (workflowInstance.properties.previousDrafts && workflowInstance.properties.previousDrafts.length > 0) {
                            const prevDraft = workflowInstance.properties.previousDrafts[workflowInstance.properties.previousDrafts.length - 1];
                            if (Array.isArray(prevDraft) && prevDraft.length > 0) {
                                const ppath = prevDraft[0].path;
                                console.log('Found previous draft = ' + ppath);
                                const fpath = info.file[0].path;
                                if (fpath) {
                                    const flabel = info.file[0].label;
                                    openPdfDocument(fpath, flabel, workflowInstance.properties.previousDrafts, dcrPortfolio, oemPortfolio, false, refPortfolio);
                                }
                            }
                        } else {
                            
                            const fpath = info.file[0].path;
                            const flabel = info.file[0].label;
                            if (fpath) {
                                openPdfDocument(fpath, flabel, undefined, dcrPortfolio, oemPortfolio,false, refPortfolio);
                            }
                        }
                    } else if ( info.file.length > 1 ) {
                        setViewPdfs(info.file);
                        setPreviousDrafts(workflowInstance.properties.previousDrafts);
                        setViewPdfOpen(true);
                    }
                } else {
                    openPdfDocument(info.file, undefined, undefined, dcrPortfolio, oemPortfolio, false, refPortfolio);
                }
            });
    };

    const handleSelectPdfDraft = (sinst) => {
        /*
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('PDF Draft folders ' + info.draftFolders);
                // do something
            });
        */
       setSelectPdfOpen(true);
    };

    const saveSelectPdf = (target) => {
        setSelectPdfOpen(false);
        stepInstance.properties['pdfDraftFile'] = target;
        netPost('/api/workflow/step/update', stepInstance)
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                }
            }).catch ( error => {
                console.log('Could update step' + error);
            });
    };

    const handleViewSelectPdfDraft = (sinst) => {
        if ( stepInstance.properties.pdfDraftFile ) {
            if ( stepInstance.properties.pdfDraftFile.length == 1 ) {
                /*
                if ( instance.properties.previousDrafts && instance.properties.previousDrafts.length > 0) {
                    const prevDraft = instance.properties.previousDrafts[instance.properties.previousDrafts.length-1];
                    const ppath = prevDraft[0].path;
                    console.log('Found previous draft = ' + ppath);
                    const fpath = stepInstance.properties.pdfDraftFile[0].path;
                    if ( ppath && fpath ) {
                        const { UI, Core } =  pdftronInstance;
                        WorkflowModule.pageIndexChange(1);
                        
                        const { annotationManager } = pdftronInstance.Core;
                        annotationManager.setCurrentUser(UserInfo.info.name);
                        
                        UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                            Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + ppath); // Load 'pdf_vers1.pdf' on first DocumentViewer
                            Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + fpath); // Load 'pdf_vers2.pdf' on second DocumentViewer
                        })
                        UI.enableFeatures([UI.Feature.MultiViewerMode]);
                    }
                } else {
                    console.log('Single PDF...');
                    */
                    const fpath = stepInstance.properties.pdfDraftFile[0].path;
                    if (fpath) {
                        /*
                        WorkflowModule.pageIndexChange(1);
                        const { UI, Core } =  pdftronInstance;
                        UI.disableFeatures([UI.Feature.MultiViewerMode]);

                        const { annotationManager } = pdftronInstance.Core;
                        annotationManager.setCurrentUser(UserInfo.info.name);
                        
                        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + fpath);
                        */
                       // openPdfDocument(fpath);
                       const flabel = stepInstance.properties.pdfDraftFile[0].label;
                       // console.log('VIEW: label=' + flabel + ', prev=' + JSON.stringify(instance.properties.previousDrafts));
                       openPdfDocument(fpath, flabel, workflowInstance.properties.previousDrafts, dcrPortfolio, oemPortfolio, false, refPortfolio);
                    }
                //}
            } else if ( stepInstance.properties.pdfDraftFile.length > 1 ) {
                setViewPdfs(stepInstance.properties.pdfDraftFile);
                setPreviousDrafts(workflowInstance.properties.previousDrafts);
                // console.log('VIEW:  prev=' + JSON.stringify(instance.properties.previousDrafts));
                setViewPdfOpen(true);
            }
        }
    };

    const handleForm = (theStepInstance) => {
        const form = theStepInstance.properties?.form;
        if ( form ) {
            setFormInstance(form);
            setEvaluateFormOpen(true);
        }

    };

    const evaluateFormSave = (formValues) => {
        setEvaluateFormOpen(false);
        if ( stepInstance ) {
            const form = stepInstance.properties?.form;
            if (form && Array.isArray(form.fields)) {
                form.fields.forEach( (field,index) => {
                    field.value = formValues[field.name];
                });
                updateStepInstanceForm(stepInstance);
            }
        }
    };

    const updateStepInstanceForm = (stepInst) => {
        netPost('/api/workflow/step/update' , stepInst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step form: error = ' + response.status);
                    
                }
            });

    };

    /* The implicit action button based on the step type */
    const getActionButton = () => {
        let button;
        if ( stepInstance && (UserInfo.info.name === stepInstance.user || (Array.isArray(stepInstance.properties.stepUsers) && stepInstance.properties.stepUsers.includes(UserInfo.info.name)))) {
            switch ( stepInstance.properties.name ) {
                case 'EDIT_DOC':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleOpenDoc(stepInstance)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonDisabled()}
                        >
                            Open Document
                        </Button>
                    );
                    break;

                case 'CHECK_PDF_DRAFT':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleCheckPdfDraft(stepInstance)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonDisabled()}
                        >
                            Review Approval Draft
                        </Button>
                    );
                    break;
                case 'SELECT_PDF_DRAFT':
                    button = (
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                onClick={() => handleSelectPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1 }}
                                disabled={getButtonDisabled()}
                            >
                                Select PDF Draft
                            </Button>
                            { stepInstance.properties.pdfDraftFile && 
                            <Button
                                variant="outlined"
                                onClick={() => handleViewSelectPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1 }}
                                disabled={getButtonDisabled()}
                            >
                                View PDF Draft
                            </Button>
                            }
                        </React.Fragment>
                    );
                    break;
                case 'FORM':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleForm(stepInstance)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            {stepInstance.properties?.form ? stepInstance.properties.form.label : 'Fill Form'}
                        </Button>
                    );
                    break;

            }
        }
        // console.log('action name: ' + stepInstance?.properties?.name);
        return button;
    };

    const saveUploadDcr = (target) => {
        setUploadDcrOpen(false);
        // console.log('Save upload target: ' + JSON.stringify(target));
        const actions = stepInstance.properties?.actions;
        if (actions) {
            let action = actions[uploadActionIndex];
            // console.log('updating action: ' + JSON.stringify(action));
            if ( action && target.length > 0) {
                action.properties.path = target[0].path;
                action.properties.uid = target[0].uid;
                // console.log('new action: ' + JSON.stringify(action));
                updateStepInstanceForm(stepInstance);
            }
        }
        setUploadActionIndex(-1); // reset uploads
    };

    const handleUploadDCR = ( sinst, uploadIndex ) => {
        setUploadDcrOpen(true);
        setUploadActionIndex(uploadIndex);
    };

    const saveUploadDlib = (target, desc, shelfid) => {
        setUploadDlibOpen(false);
        // console.log('Save upload target: ' + JSON.stringify(target));
        const actions = stepInstance.properties?.actions;
        if (actions) {
            let action = actions[uploadActionIndex];
            // console.log('updating action: ' + JSON.stringify(action));
            if ( action && target.length > 0) {
                action.properties.files = target;
                action.properties.actionState = 'FINISHED';
                action.state = 'FINISHED';
                action.properties.description = desc;
                action.properties.shelfId = shelfid;
                netPost('/api/workflow/step/update', stepInstance)
                    .then(response => {
                        if (!response.ok) {
                            console.log('Could not update step form: error = ' + response.status);
                        } else {
                            netGet(`/api/workflow/action/complete/${workflowInstance.id}/${uploadActionIndex}`)
                                .then(response => {
                                    if (!response.ok) {
                                        console.log('Could not run dlib shelf upload action: error = ' + response.status);

                                    }
                                });
                        }
                    });
                
            }
        }
        setUploadActionIndex(-1); // reset uploads
    };

    const handleUploadDLIB = (sinst, uploadIndex) => {
        setUploadDlibOpen(true);
        setUploadActionIndex(uploadIndex);
        const actions = stepInstance.properties?.actions;
        if ( Array.isArray(actions) ) {
            const action = actions[uploadIndex];
            if (action) {
                if ( action.properties?.description) {
                    setUploadDlibDescription(action.properties.description);
                }
                if ( action.properties?.shelfId) {
                    setUploadDlibShelf(action.properties.shelfId);
                }
            }
        }
    };

    const handleAbortDraft = ( sinst, index) => {
        setAbortDialogOpen(true);
        setActionIndex(index);
        /*
        sinst.properties.completeAction = 'ABORT';
        console.log('Aborted step: ' + JSON.stringify(sinst) );
        netPost('/api/workflow/step/update' , sinst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step to abort: error = ' + response.status);
                    
                } else {
                    // this should abort the draft and put step back in unlocked state
                    setActionButtonDisabled(true);
                    netGet('/api/workflow/step/complete/' + instance.id)
                        .then(response => {
                            if (!response.ok) {
                                console.log('Could not complete step: error = ' + response.status);
                            }
                        });
                }
            });
        */
    };

    const abortConfirmSave = (index) => {
        setAbortDialogOpen(false);
        netGet(`/api/workflow/action/complete/${workflowInstance.id}/${index}?action=ABORT` )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not abort action: error = ' + response.status);
                    
                }
            });
        /*
        stepInstance.properties.completeAction = 'ABORT';
        // console.log('Aborted step: ' + JSON.stringify(sinst) );
        netPost('/api/workflow/step/update' , stepInstance )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step to abort: error = ' + response.status);
                    
                } else {
                    // this should abort the draft and put step back in unlocked state
                    setActionButtonDisabled(true);
                    netGet('/api/workflow/step/complete/' + workflowInstance.id)
                        .then(response => {
                            if (!response.ok) {
                                console.log('Could not complete step: error = ' + response.status);
                            }
                        });
                }
            });
        */
    }

    const handleEditionOptions = (sinst, draftIndex) => {
        setDraftOptionsOpen(true);
        setEditionOptionsFlag(true);
    }

    const handleDraftOptions = (sinst, draftIndex) => {
        setDraftOptionsOpen(true);
    };

    const cancelDraftOptions = () => {
        setDraftOptionsOpen(false);
        setEditionOptionsFlag(false);
    }

    const saveDraftOptions = (options) => {
        setDraftOptionsOpen(false);
        setEditionOptionsFlag(false);
        setDraftOptions(options);
        if ( Array.isArray(stepInstance?.properties?.actions) ) {
            stepInstance.properties.actions.forEach( action => {
                if ( action.name === 'RUN_DRAFT' || action.name === 'RUN_APPROVAL_DRAFT' || action.name === 'RUN_EDITION_PAUSE') {
                    if ( action.properties ) {
                        action.properties = {...action.properties, ...options};
                    } else {
                        action.properties = { ...options};
                    }
                }
            });
        }
        netPost('/api/workflow/step/update' , stepInstance )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step: error = ' + response.status);
                    
                }
            });
    };

    const handleViewProcessLogs = (sinst, index) => {
        const pid = workflowInstance.properties.jobId;
        console.log("PID = " + pid);
        setProcessInstId(pid);
        setViewProcessLogsOpen(true)
    };

    const handleViewDraftOutput = (sinst, index) => {
        // console.log("PDRAFTS: " + JSON.stringify(workflowInstance?.properties?.previousDrafts));
        if (workflowInstance.properties.previousDrafts && workflowInstance.properties.previousDrafts.length > 0) {
            setPreviousDrafts(workflowInstance.properties.previousDrafts);
        } else {
            setPreviousDrafts([]);
        }
        setViewDraftOutputOpen(true);
    }

    const handleViewEditionOutput = (sinst, index) => {
        if (workflowInstance.properties.previousDrafts && workflowInstance.properties.previousDrafts.length > 0) {
            setPreviousDrafts(workflowInstance.properties.previousDrafts);
        } else {
            setPreviousDrafts([]);
        }
        setViewEditionOutputOpen(true);
    }

    const viewDraftOutputSave = (target) => {
        setViewDraftOutputOpen(false);
        if ( target && target.path && target.label) {
            openPdfDocument(target.path, target.label, previousDrafts, dcrPortfolio, oemPortfolio, false, refPortfolio);
        }
    };

    const viewEditionOutputSave = (target) => {
        setViewEditionOutputOpen(false);
        if ( target && target.path && target.label) {
            openPdfDocument(target.path, target.label, previousDrafts, dcrPortfolio, oemPortfolio, false, refPortfolio);
        }
    };

    const handleStartDraftOrEdition = (sinst, index, action) => {
        netGet(`/api/workflow/action/complete/${workflowInstance.id}/${index}?action=START&name=${action.name}` )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);
                    
                }
            });
    };

    const handleStartAction = (sinst, index, action, verb='START') => {
        netGet(`/api/workflow/action/complete/${workflowInstance.id}/${index}?action=${verb}&name=${action.name}` )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);
                    
                }
            });
    };

    const haveAllActionsCompleted = () => {
        let r = true;
        if ( stepInstance.properties?.actions && Array.isArray(stepInstance.properties.actions) ) {
            
            stepInstance.properties.actions.forEach( (action,index) => {
                const astate = action.properties?.actionState;
                // failed is considered a "success" as we may want to get out of this step to fix things
                const exec = action.properties?.exec === 'USER' || typeof action.properties?.exec === 'undefined';
                const ar = (astate === 'FINISHED' || astate === 'COMPLETED' || astate === 'FAILED');
                if ( exec && (action.properties.required || action.name === 'RUN_DRAFT'  || action.name === 'RUN_APPROVAL_DRAFT' || action.name === 'RUN_EDITION_PAUSE'
                    || (action.name === 'UPLOAD' && action.properties?.target === 'DLUP') 
                || action.name === 'OPEN_EDITION'  || action.name === 'OPEN_SHELF'  || action.name === 'SEND_EMAIL' 
                || action.name === 'EDIT_EXTERNAL_DOC' || action.name === 'APPROVE_EXTERNAL_DOC'
                || action.name === 'CHECK_EXTERNAL_DOC' || action.name === 'APPROVE_EXTERNAL_DOC_OWNER'
                || action.name === 'APPROVE_EXTERNAL_DOC_CORP') ) { // test batch vs USER: OPEN_SHELF and SEND_MAIL
                    r = r && ar;
                }
                if ( action.properties?.exec === 'ENTER' ) {
                    if (action.properties.required || action.name === 'RUN_DRAFT'  || action.name === 'RUN_APPROVAL_DRAFT' || action.name === 'RUN_EDITION_PAUSE' 
                        || action.name === 'OPEN_EDITION'  || action.name === 'OPEN_SHELF'
                    ) {
                        r = r && ar;
                    }
                }
            });
        }
        // console.log('actions completed: ' + r);
        return r;
    };

const handleDownloadFiles = (action) => {
    let document = workflowDef.attributes.document;
    if (!document) {
        document = workflowInstance.properties.document;
    }
    // console.log('DOWN ACTION: ' + JSON.stringify(action));
    const url = `/api/doc/download/profile/${document}?pf=${action.properties?.target}`;
    setDownloadFiles([]);
    netGet(encodeURI(url))
        .then(response => response.json())
        .then(dfiles => {
            if (Array.isArray(dfiles)) {
                // console.log('Got ' + dfiles.length + ' download files.\n' + JSON.stringify(dfiles));
                const dmap = dfiles.map(file => {
                    return { url: encodeURI('/file/' + UserInfo.info.sessionId + file.path), name: file.name };
                });
                setDownloadFiles(dmap);
                setDownloadDialogOpen(true);
                // console.log('DOWN ACTION: ' + JSON.stringify(action));
            }
        }).catch(error => {
            console.log('Error fetching download profiles: ' + error);
            // console.log('DOWN ACTION: ' + JSON.stringify(action));
        });
};        
        

    /* Array of generic workflow actions using the Action definition object */
    // MOVING
    const getWorkflowActionButtons = () => {
        let buttons = [];
        if ( stepInstance && (UserInfo.info.name === stepInstance.user || (Array.isArray(stepInstance.properties.stepUsers) && stepInstance.properties.stepUsers.includes(UserInfo.info.name)))) {
            if ( stepInstance.properties?.actions && Array.isArray(stepInstance.properties.actions) ) {
                // console.log('Get workflow actions: ' + JSON.stringify(stepInstance.properties.actions));
                stepInstance.properties.actions.forEach( (action,index) => {
                    if ( action.properties?.exec === 'EXIT' || action.properties?.exec === 'ACTION' ) {
                        // no buttons, actions executed silently on step movement
                        // maybe some status info on some actions later
                    } else if ( action.properties?.exec === 'ENTER' || action.properties?.exec === 'CLAIM' ) {

                    } else if ( action.name === 'UPLOAD' && action.properties?.target === 'DCR') {
                        // console.log('Found DCR actions: ' + JSON.stringify(action));
                        const button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleUploadDCR(stepInstance,index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Upload DCR File
                        </Button>
                        );
                        buttons.push(button);
                    } else if ( action.name === 'UPLOAD' && action.properties?.target === 'DLUP') {
                        // console.log('Found DCR actions: ' + JSON.stringify(action));
                        const button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleUploadDLIB(stepInstance,index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Upload Digital Library Files
                        </Button>
                        );
                        buttons.push(button);
                    } else if ( action.name === 'UPLOAD' && action.properties?.target === 'REF') {
                        // console.log('Found DCR actions: ' + JSON.stringify(action));
                        const button = (
                        <Button
                            variant="outlined"
                            onClick={() =>  handleUploadDLIB(stepInstance,index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Upload Reference Files
                        </Button>
                        );
                        buttons.push(button);
                    } else if ( action.name === 'DOWNLOAD') {
                        // console.log('Found DCR actions: ' + JSON.stringify(action));
                        const button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleDownloadFiles(action)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Download Files
                        </Button>
                        );
                        buttons.push(button);
                    } else if ( action.name === 'RUN_DRAFT'  || action.name === 'RUN_APPROVAL_DRAFT') {
                        if (action.properties?.actionState === 'RUNNING') {
                            // console.log('Found RunDraft action: ' + JSON.stringify(action));
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={true}
                                    color="info"
                                >
                                    In Progress
                                </Button>
                            );
                            buttons.push(button1);

                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleAbortDraft(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1, ml: 0 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Abort Process
                                </Button>
                            );
                            buttons.push(button2);

                            const button3 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    View Process Log
                                </Button>
                            );
                            buttons.push(button3);
                        } else if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    Process Completed
                                </Button>
                            );
                            buttons.push(button1);
                            const button3 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    View Process Log
                                </Button>
                            );
                            buttons.push(button3);
                        } else if (action.properties?.actionState === 'FAILED' ) {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="error"
                                >
                                    Process Failed
                                </Button>
                            );
                            buttons.push(button1);
                            const button3 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    View Process Log
                                </Button>
                            );
                            buttons.push(button3);
                        } else {
                            const button = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleDraftOptions(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1, ml: 0}}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Set Options
                                </Button>
                            );
                            buttons.push(button);
                            
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Process
                                </Button>
                            );
                            buttons.push(button2);
                            
                        }
                    } else if ( action.name === 'RUN_EDITION_PAUSE' ||  action.name === 'RESUME_EDITION' || action.name === 'OPEN_EDITION') {
                        if (action.properties?.actionState === 'RUNNING') {
                            // console.log('Found RunDraft action: ' + JSON.stringify(action));
                            // abort action is not draft specific
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={true}
                                    color="info"
                                >
                                    In Progress
                                </Button>
                            );
                            buttons.push(button1);
                            if (action.name === 'RUN_EDITION_PAUSE') {
                                const button2 = (
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleAbortDraft(stepInstance, index)}
                                        sx={{ mt: 1, mr: 1, ml: 0 }}
                                        disabled={getButtonRolesDisabled()}
                                    >
                                        Abort Process
                                    </Button>
                                );
                                buttons.push(button2);
                            }
                            const button3 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                                </Button>
                            );
                            buttons.push(button3);
                        } else if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    Process Completed
                                </Button>
                            );
                            buttons.push(button1);
                            const button3 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    {action.name === 'RUN_EDITION_PAUSE'  || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                                </Button>
                            );
                            buttons.push(button3);
                        } else if (action.properties?.actionState === 'FAILED' ) {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="error"
                                >
                                    Process Failed
                                </Button>
                            );
                            buttons.push(button1);
                            const button3 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    {action.name === 'RUN_EDITION_PAUSE'  || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                                </Button>
                            );
                            buttons.push(button3);
                        } else {
                            if (  action.name === 'RESUME_EDITION' ) {
                                const button = (
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleViewEditionOutput(stepInstance, index)}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={getButtonRolesDisabled()}
                                    >
                                        View Edition
                                    </Button>
                                );
                                buttons.push(button);
                                const button3 = (
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={getButtonRolesDisabled()}
                                    >
                                        Sign
                                    </Button>
                                );
                                buttons.push(button3);
                            } else if (  action.name === 'OPEN_EDITION' ) {
                                    const button3 = (
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={getButtonRolesDisabled()}
                                        >
                                            Process
                                        </Button>
                                    );
                                    buttons.push(button3);
    
                            } else { // RUN_EDITION
                                const button = (
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleEditionOptions(stepInstance, index)}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={getButtonRolesDisabled()}
                                    >
                                        Set Options
                                    </Button>
                                );
                                buttons.push(button);
                                const button2 = (
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={getButtonRolesDisabled()}
                                    >
                                        Process
                                    </Button>
                                );
                                buttons.push(button2);
                            }
                        }
                    } else if ( action.name === 'VIEW_DRAFT' ) {
                        const button = (
                            <Button
                                    variant="outlined"
                                    onClick={() => handleViewDraftOutput(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled() || !viewDraftEnable}
                                >
                                    Review Check Draft
                                </Button>
                        );
                        buttons.push(button);
                        const button2 = (
                            <Button
                                variant="outlined"
                                onClick={() => handleViewProcessLogs(stepInstance, index)}
                                sx={{ mt: 1, mr: 1 }}
                                disabled={getButtonRolesDisabled()}
                            >
                                View Process Log
                            </Button>
                        );
                        buttons.push(button2);
                    } else if ( action.name === 'VIEW_APPROVAL_DRAFT' ) {
                        const button = (
                            <Button
                                variant="outlined"
                                onClick={() => handleCheckPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1}}
                                disabled={getButtonDisabled() || !approvalDraftEnable}
                            >
                                {approvalDraftLabel}
                            </Button>
                        );
                        buttons.push(button);
                      
                    } else if ( action.name === 'EDIT_CAND_DRAFT' || action.name === 'REVIEW_CAND_DRAFT' || action.name === 'ANNOT_CAND_DRAFT') {
                        const button = (
                            <Button
                                variant="outlined"
                                onClick={() => handleCandidateDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1}}
                                disabled={getButtonDisabled() || !candidateDraftEnable}
                            >
                                {action.name === 'REVIEW_CAND_DRAFT' ? REVIEW_CANDIDATE_DRAFT_LABEL: ANNOTATE_CANDIDATE_DRAFT_LABEL}
                            </Button>
                        );
                        buttons.push(button);
                        
                    } else if ( action.name === 'EDIT_OEM_DCR' || action.name === 'REVIEW_OEM_DCR' || action.name === 'ANNOT_OEM_DCR') {
                        const button = (
                            <Button
                                variant="outlined"
                                onClick={() => handleEditOemCopy(stepInstance)}
                                sx={{ mt: 1, mr: 1}}
                                disabled={getButtonDisabled() || !oemCopyEnable}
                            >
                                {action.name === 'REVIEW_OEM_DCR' ? REVIEW_OEM_DCR_LABEL : ANNOTATE_OEM_DCR_LABEL}
                            </Button>
                        );
                        buttons.push(button);
                        
                    } 
                    
                    else if ( action.name === 'PROMOTE_DRAFT' ) {
                        if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    Draft Promoted
                                </Button>
                            );
                            buttons.push(button1);

                        } else {
                            const button = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewDraftOutput(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    View Draft
                                </Button>
                            );
                            buttons.push(button);
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleStartAction(stepInstance, index, action)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Promote
                                </Button>
                            );
                            buttons.push(button2);
                        }
                    
                    }    else if ( action.name === 'OPEN_SHELF' ) {
                        if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    Library Shelf Created
                                </Button>
                            );
                            buttons.push(button1);

                        } else {
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleStartAction(stepInstance, index, action)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Open New Library Shelf
                                </Button>
                            );
                            buttons.push(button2);
                        }
                    }    else if ( action.name === 'COPY_PREV_EDITION' ) {
                        if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    Previous Edition Copied
                                </Button>
                            );
                            buttons.push(button1);

                        } else {
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleStartAction(stepInstance, index, action)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Copy Previous Edition
                                </Button>
                            );
                            buttons.push(button2);
                        }
                    }    else if ( action.name === 'COPY_OEM_DCR' ) {
                        if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    OEM Revision Copied
                                </Button>
                            );
                            buttons.push(button1);

                        } else {
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleStartAction(stepInstance, index, action)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Copy OEM Revision
                                </Button>
                            );
                            buttons.push(button2);
                        }
                    }    else if ( action.name === 'SEND_EMAIL' ) {
                        if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                            const button1 = (
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={false}
                                    color="success"
                                >
                                    Email Sent
                                </Button>
                            );
                            buttons.push(button1);

                        } else {
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleStartAction(stepInstance, index, action)}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    Send Email
                                </Button>
                            );
                            buttons.push(button2);
                        }
                    }
                });

            }
        }
        return buttons;
    };

    const getClaimActionButtons = () => {
        let buttons = [];
        const label = getButtonLabel();
        if (label === 'Claim') {
            if (stepInstance && stepInstance.properties?.actions && Array.isArray(stepInstance.properties.actions)) {
                stepInstance.properties.actions.forEach((action, index) => {
                    if (action.name === 'RUN_DRAFT' || action.name === 'RUN_APPROVAL_DRAFT' || 
                        action.name === 'RUN_EDITION_PAUSE' || action.name === 'RESUME_EDITION') {
                        if (stepInstance.properties?.state === 'FAILED') {
                            const button2 = (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewProcessLogs(stepInstance, index)}
                                    sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                                    disabled={getButtonRolesDisabled()}
                                >
                                    View Process Log
                                </Button>
                            );
                            buttons.push(button2);
                            const button3 = (
                                <Typography sx={{display: 'inline-block', color: 'red', paddingLeft: '1ex', position: 'relative', top: '5px' }}>PROCESS FAILED</Typography>
                            );
                            buttons.push(button3);
                        }
                    }
                });
            }
        }
        return buttons;
    }

    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // console.log('handleNext: ' + JSON.stringify(UserInfo) + ' : iuser: ' + JSON.stringify(stepInstance));
        if (UserInfo && UserInfo.info && stepInstance) {
            // console.log('cuser: ' + UserInfo.info.name + ' : iuser: ' + stepInstance.user);
            stepInstance.properties['state'] = 'START';
            netPost('/api/workflow/step/update', stepInstance)
                .then(response => {
                    if (response.ok) {
                        setActionButtonDisabled(true);
                        if (UserInfo.info.name === stepInstance.user) {
                            netGet('/api/workflow/step/complete/' + workflowInstance.id)
                                .then(response => {
                                    if (!response.ok) {
                                        console.log('Could not complete step: error = ' + response.status);
                                    }
                                });
                        } else {
                            netGet('/api/workflow/step/claim/' + workflowInstance.id)
                                .then(response => {
                                    if (!response.ok) {
                                        console.log('Could not claim step: error = ' + response.status);
                                    }
                                });
                        }
                    }
                });
        }
    };

    const debouncedHandleNext = useCallback(
        debounce(handleNext, DEBOUNCE_TIME)
      , [stepInstance]);

    return (
        <React.Fragment>
            <table style={{ padding: 0, borderSpacing: 0, paddingTop: '1ex', paddingBottom: '1ex' }}>
                <tbody>
                    <tr>
                        <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>Stakeholders: </Typography>
                        </td>
                        <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                            <Typography component="span" >{
                                stepInstance && stepInstance.properties && Array.isArray(stepInstance.properties.participants) &&
                                stepInstance.properties.participants.reduce((total, user, index) => {
                                    if (index > 0) {
                                        total = total + ', ';
                                    }
                                    total = total + user;
                                    return total;
                                }, '')

                            }</Typography>
                        </td>
                    </tr>
                    <tr>
                        {stepInstance && stepInstance.user ?
                            <React.Fragment>
                                <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Assigned to: </Typography>
                                </td>
                                <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                    <Typography component="span">{
                                        Array.isArray(stepInstance.properties.stepUsers) ?
                                            stepInstance.properties.stepUsers.reduce((total, user, index) => {
                                                if (index > 0) {
                                                    total = total + ',';
                                                }
                                                total = total + user;
                                                return total;
                                            }, '')
                                            : stepInstance.user
                                    }</Typography>
                                </td>
                            </React.Fragment> :
                            <td colSpan="2" style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                <Typography component="span" sx={{ fontWeight: 'bold' }}>Not Assigned</Typography>
                            </td>
                        }
                    </tr>
                    {stepDef.properties && stepDef.properties.multiLock &&
                        <tr>
                            <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                <Typography component="span" sx={{ fontWeight: 'bold' }}>Completed: </Typography>
                            </td>
                            <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                <Typography component="span">{
                                    stepInstance && stepInstance.properties && Array.isArray(stepInstance.properties.completedUsers) &&
                                    stepInstance.properties.completedUsers.reduce((total, user, index) => {
                                        if (index > 0) {
                                            total = total + ', ';
                                        }
                                        total = total + user;
                                        return total;
                                    }, '')

                                }</Typography>
                            </td>
                        </tr>
                    }

                    <tr>
                        {stepInstance && stepInstance.startDate ?
                            <React.Fragment>
                                <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Started: </Typography>
                                </td>
                                <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                    <Typography component="span">{stepInstance.startDate.toLocaleString()}</Typography>
                                </td>
                            </React.Fragment> :
                            <td colSpan="2" style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                <Typography component="span" sx={{ fontWeight: 'bold' }}>Not Started</Typography>
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
            { stepInstance && canAction() &&
                <Box sx={{ mb: 1, width: '100%', display: 'flex', alignItems: 'center', mt: 1  }}>
                    <Typography sx={{ fontWeight: 'bold', width: '7em', whiteSpace: 'nowrap' }}>Actions:</Typography>
                
                    <WorkflowActionButtons workflowDef={workflowDef} workflowInstance={workflowInstance} stepInstance={stepInstance} dcrPortfolio={dcrPortfolio}
                        oemPortfolio={oemPortfolio} refPortfolio={refPortfolio} />
                    {getActionButton()}
                    {getWorkflowActionButtons()}
                    
                    
                </Box> }

            { (stepInstance && canComplete() ) ?
                <React.Fragment>
                    <Box sx={{ mb: 1, width: '100%', display: 'flex', alignItems: 'center', mt: 3 }}>
                        <Typography sx={{ fontWeight: 'bold', width: '7em', whiteSpace: 'nowrap' }}>Destination:</Typography>
                        <FormControl sx={{ m: 1, ml: 0, minWidth: "20px" }} size="small">
                            <Select
                                value={getStepRoutes(stepDef).length < 2 ? 0 : destStepIndex}
                                onChange={(event) => handleGotoStepChange(event, stepInstance)}
                                sx={{ minWidth: '8em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                readOnly={!canComplete() || getStepRoutes(stepDef).length < 2}
                                disabled={!canComplete() || getStepRoutes(stepDef).length < 2}
                                displayEmpty={true}
                                placeholder="Select"
                            > 
                                {getStepRoutes(stepDef).length > 1 &&
                                    <MenuItem disabled value={''}>
                                        Select
                                    </MenuItem>
                                }
                                {getStepRoutes(stepDef, workflowDef, workflowDefList, stepInstance).map((w, i) => (
                                    <MenuItem
                                        key={'gts' + i}
                                        value={i}
                                        disabled={isRouteDisabled(w)}
                                    >
                                        {w.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            onClick={debouncedHandleNext}
                            sx={{ marginLeft: '2em' }}
                            disabled={getButtonDisabled() || !haveAllActionsCompleted() || !getButtonRoutesEnabled()}
                        >
                            {getButtonLabel()}
                        </Button>
                        {/*
                        <CircularProgress
                            variant="indeterminate"
                            sx={{
                                color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                animationDuration: '550ms',
                                [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                },
                                visibility: (actionButtonDisabled ? 'visible' : 'hidden'),
                                position: 'relative',
                                top: 10,
                                right: 10,
                                zIndex: 10,
                                padding: 0,
                                float: 'right'
                            }}
                            size={34}
                            thickness={4}
                        /> */}
                    </Box>
                </React.Fragment>
                :
                <React.Fragment>
                    <Button
                            variant="contained"
                            onClick={debouncedHandleNext}
                            sx={{ ml: 0, mt: 1 }}
                            disabled={getButtonDisabled()}
                        >
                            {getButtonLabel()}
                        </Button>
                        {/*
                        <CircularProgress
                            variant="indeterminate"
                            sx={{
                                color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                animationDuration: '550ms',
                                [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                },
                                visibility: (actionButtonDisabled ? 'visible' : 'hidden'),
                                position: 'relative',
                                top: 10,
                                right: 10,
                                zIndex: 10,
                                padding: 0,
                                float: 'right'
                            }}
                            size={34}
                            thickness={4}
                        /> */}
                </React.Fragment>
            }
            

            <ChooseViewPdfDialog open={viewPdfOpen} onCancel={() => setViewPdfOpen(false)} files={viewPdfs} 
                previousDrafts={previousDrafts} dcrPortfolio={dcrPortfolio} oemPortfolio={oemPortfolio} />

            <ChooseReviewDCRBindersDialog open={dcrDocumentReviewOpen} onCancel={() => setDcrDocumentReviewOpen(false)} documents={dcrDocuments} onSave={handleReviewDCRSave} />

            <SelectPdfDraftDialog open={selectPdfOpen} onCancel={() => setSelectPdfOpen(false)} onSave={saveSelectPdf} workflowInstance={workflowInstance} stepInstance={stepInstance} />
            <UploadFileDialog open={uploadDcrOpen} onCancel={() => setUploadDcrOpen(false)} onSave={saveUploadDcr} workflowInstance={workflowInstance} stepInstance={stepInstance} />
            <SimpleUploadFileDialog open={uploadDlibOpen} onCancel={() => setUploadDlibOpen(false)} onSave={saveUploadDlib} workflowInstance={workflowInstance} stepInstance={stepInstance} 
                description={uploadDlibDescription} shelf={uploadDlibShelf} />
            <EvaluateFormDialog open={evaluateFormOpen} onCancel={() => setEvaluateFormOpen(false)} onSave={evaluateFormSave} form={formInstance} workflowInstance={workflowInstance} 
                stepInstance={stepInstance} />
            <ProcessOptionsDialog title={editionOptionsFlag ? 'Run Edition Options' : 'Run Draft Options'}
                open={draftOptionsOpen} onCancel={cancelDraftOptions} onSave={saveDraftOptions} options={draftOptions} isEdition={editionOptionsFlag} />
            <ViewDraftOutputDialog open={viewDraftOutputOpen} onCancel={() => setViewDraftOutputOpen(false)} onSave={viewDraftOutputSave}
                workflowInstance={workflowInstance} stepInstance={stepInstance} isEdition={false}/>
            <ViewDraftOutputDialog open={viewEditionOutputOpen} onCancel={() => setViewEditionOutputOpen(false)} onSave={viewEditionOutputSave}
                workflowInstance={workflowInstance} stepInstance={stepInstance} isEdition={true} title="Edition Outputs" />
            <ProcessLogViewer open={viewProcessLogsOpen} onClose={() => setViewProcessLogsOpen(false)} pid={processInstId} />
            <AbortConfirmDialog open={abortDialogOpen} onCancel={() => setAbortDialogOpen(false)} onAbort={abortConfirmSave} index={actionIndex} />
            <DownloadFilesDialog files={downloadFiles} open={downloadDialogOpen} onCancel={() => setDownloadDialogOpen(false)} />
        </React.Fragment>
    );

};

export { WorkflowStep };